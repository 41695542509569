import React, {useContext} from 'react'
import '../EVG/css/_core.scss';

import Header from './Header'

import { Helmet } from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax'

import FsLightbox from 'fslightbox-react'
import FSLightBoxContext from '../context/FSLightBoxContext'

if (typeof window !== 'undefined') {
	// eslint-disable-next-line global-require
	require('smooth-scroll')('a[href*="#"]', {
		speed: 800,
		speedAsDuration: true,
		easing: 'easeInOutCubic'
	})
}

function Layout({ children }) {
	const lightBoxContext = useContext(FSLightBoxContext)

  return (
  	<>

			<Helmet>
				<link href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@700&display=swap" rel="stylesheet" />
      </Helmet>


			<Helmet>
				<script async src="https://www.googletagmanager.com/gtag/js?id=AW-780998942"></script>

				<script>{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', 'AW-780998942');
				`}
				</script>
			</Helmet>

			<Helmet>
				<script>{`
					function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,'script',
					'https://connect.facebook.net/en_US/fbevents.js');
					fbq('init', '220146613843634');
					fbq('track', 'PageView');
				`}
				</script>
				<noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=220146613843634&ev=PageView&noscript=1"/>`}</noscript>
			</Helmet>

			<Header />
			
			<ParallaxProvider>
				{children}
			</ParallaxProvider>

			{lightBoxContext.sources ?
				<FsLightbox
					toggler={lightBoxContext.toggler}
					sources={lightBoxContext.sources}
					slide={lightBoxContext.slide}
					key={lightBoxContext.sources[0]}
					type="image"
				/>
			: null }

  	</>
  )
}

export default Layout
