import React, {useContext, useEffect, useState} from 'react'
import './style.scss'
import NaviContext from '../../../context/NaviContext'

import Logo from '../Logo'
import { Link } from 'gatsby'

function Hamburger(props) {
	const naviContext = useContext(NaviContext)

	// Keeping track of Header Logo Width
	const [logoWidthOffset, setLogoWidthOffset] = useState(0)
	useEffect(() => {
		const logo = document.getElementById('master-logo')
		//const hamburger = document.getElementById('master-hamburger-container')
		const offset = logo.clientWidth + 10 // - hamburger.clientWidth
		setLogoWidthOffset(offset)
	}, [])
	
  return (
		<>
			<div id="master-hamburger" className="c5">
				<Logo />

				<div id="master-hamburger-positioner" onClick={() => naviContext.activeToggle(true)} onKeyDown={() => naviContext.activeToggle(true)} role="button" tabIndex={0}>
						<div id="master-hamburger-container">
							<div className={naviContext.isActive ? 'hamburger-container hamburger hamburger--close1 open' : 'hamburger-container hamburger hamburger--close1'}>
								<div className="hamburger__icon">
									<div className="hamburger__line hamburger__line--1"></div>
									<div className="hamburger__line hamburger__line--2"></div>
									<div className="hamburger__line hamburger__line--3"></div>
								</div>
							</div>
						</div>
				</div>
			</div>
			<div className={`quick-contact ${naviContext.beforeHeaderBreakpoint ? 'inactive' : 'active'}`}>
				<div className="wrap c3">
					<Link className="sticky-contact" to="/lets-connect" onClick={() => naviContext.setActive(false)} onKeyDown={() => naviContext.setActive(false)}>Contact</Link>
				</div>
			</div>
		</>
  )
}

export default Hamburger