import React, {useState, createContext, useEffect} from 'react'
import axios from 'axios'

const APIContext = createContext(false)

function APIContextProvider(props) {
	const [data, setData] = useState()
	const [API, setAPI] = useState()

	const urlBase = `https://cms.deepsleepstudio.com/client/SWARM/wp-json/wp/v2/`
	const refreshBase = 60000

  /* ==========================================================================
    Active API endpoints & data gathering & execution 
	========================================================================== */
	
	function organizeData(incomingData) {
		// If we have axios response
		if(incomingData) {

			// Prep Incoming Data
			let incomingDataUnified = []
			if(Array.isArray(incomingData)) {
				incomingDataUnified.push(...incomingData)
			} else {
				incomingDataUnified.push({...incomingData})
			}

			setData(prevData => {

				// If any data is stored in state
				if(prevData) {

					// Clone old data as current
					let currentData = [...prevData]

					// Go through incoming data and see if we already have it in our main data
					for (var iKey in incomingDataUnified) {

						// IDs of posts
						const incomingId = incomingDataUnified[iKey].id

						// Set flag to check if we updated already
						let updatedFlag = false

						// Go through current data 
						for (var dKey in currentData) {

							// IDs of posts
							const currentId = currentData[dKey].id

							// If it exists, update
							if(incomingId === currentId) {

								// Update
								currentData[dKey] = incomingDataUnified[iKey]
								// Set flag that we did update something
								updatedFlag = true
								//console.log('updated')
								// Cut it out
								break
							}
						}

						// If it doesn't exist, and we didn't update anything add to data array
						if (!updatedFlag) {

							// Add to array
							currentData.push(incomingDataUnified[iKey])
							//console.log('added')
						}
					}
					// Update state with our new-current data
					return currentData

				// or...
				} else {

					// Fill state with fresh data
					return incomingDataUnified
				}
			})
		}
	}

  /* ==========================================================================
    API Autoupdating SiteWide Context
  ========================================================================== */

  useEffect(() => {
		// Fetch API via axios get
		const fetchAPI = async () => {
			// Construct get url
			const url = API.id ?
									urlBase + API.path + `/` + API.id
								: urlBase + API.path + `/`
			try {
				const response = await axios.get(url)
				// Run data organizer to store and update newly gathered data
				organizeData(response.data)
			} catch (err) {
				console.error(err.message);
			}
		}

		if(API) {
			// Initial fetch on page load
			fetchAPI()

			// Reoccuring interval, that could increase or decrease polling rate depending on API request by component
			const interval = setInterval(() => {
				fetchAPI()
			}, API.refresh ? API.refresh : refreshBase)
			return () => clearInterval(interval)
		}

  },[API, urlBase, refreshBase])

	return (
		<APIContext.Provider value={{
			data,
			setAPI: (que) => setAPI(que)
    }}>
      {props.children}
    </APIContext.Provider>
	)
}

export default APIContext
export { APIContextProvider }