import React from 'react'
import './style.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'

function SocialIcons(props) {
	
  return (
		<>
			<div className='social-icons'>
				<div className='social-icon hint--rounded hint--left' data-hint='FaceBook'>
					<a href='https://facebook.com/SWARMeventagency' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
				</div>
				<div className='social-icon hint--rounded hint--left' data-hint="Twitter">
					<a href='https://twitter.com/SWARMwithus' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faTwitter} />
					</a>
				</div>
				<div className='social-icon hint--rounded hint--left' data-hint="SWARM Radio">
					<a href='https://swarm-radio.simplecast.com/episodes' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faMicrophone} />
					</a>
				</div>
				<div className='social-icon hint--rounded hint--left' data-hint='Instagram'>
					<a href='https://instagram.com/swarm' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faInstagram} />
					</a>
				</div>
				{/* <div className='social-icon hint--rounded hint--bottom' data-hint='YouTube'>
					<a href='https://youtube.com' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faYoutube} />
					</a>
				</div> */}
				{/* <div className='social-icon hint--rounded hint--bottom' data-hint='Pinterest'>
					<a href='https://pinterest.com' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faPinterest} />
					</a>
				</div> */}
			</div>
		</>
  )
}

export default SocialIcons