import React, {useContext} from 'react'
import NaviContext from '../../../context/NaviContext'

import logoIMG from './logo_stroke.png'
import './style.scss'

function Logo(props) {
	const naviContext = useContext(NaviContext)

  return (
		<div id="master-logo" className='logo' onClick={() => naviContext.activeToggle(true)} onKeyDown={() => naviContext.activeToggle(true)} role="button" tabIndex={0}>
			<img src={logoIMG} alt="Logo" />
		</div>
  )
}

export default Logo