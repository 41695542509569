import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition } from 'react-transition-group'
import anime from 'animejs'

import Hamburger from './Hamburger'
import Navi from './Navi'
import SocialIcons from '../SocialIcons'

function Header(props) {
	const naviContext = useContext(NaviContext)

	// Animations
	const baseDuration = 400
	// Animating fade in/out
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item a')
		const decor = element.querySelectorAll('.navi-decor')
		const socialLinks = element.querySelectorAll('.social-icon')
		anime
			.timeline()
			.add({
				targets: element,
				translateY: ['-100%', 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
			})
			.add({
				targets: links,
				opacity: [0,1],
				translateY: [-20, 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
				delay: anime.stagger(60)
			}, `-=${baseDuration * 0.2}`)
			.add({
				targets: decor,
				opacity: [0, 1],
				translateX: ['-100%', 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
			}, `-=${baseDuration * 0.85}`)
			.add({
				targets: socialLinks,
				opacity: [0, 1],
				translateY: [-10, 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
				delay: anime.stagger(100)
			}, `-=${baseDuration * .75}`)
	}
	const fadeOutLogo = element => {
		anime
			.timeline()
			.add({
				targets: element,
				translateY: [0,'-100%'],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)'
			})
	}
	// Animating fade in/out
	const fadeInSocialBar = element => {
		const links = element.querySelectorAll('.social-icon')
		anime
			.timeline()
			.add({
				targets: element,
				translateY: ['-100%', 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				translateX: [-40, 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
				delay: anime.stagger(50)
			}, `-=${baseDuration * 0.25}`)
	}
	const fadeOutSocialBar = element => {
		anime
			.timeline()
			.add({
				targets: element,
				translateY: [0,'-100%'],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)'
			})
	}

  return (
		<header id='master-header' className={'c5'}>
			<Hamburger />

			<div className="bg c5"></div>

			{/* {naviContext.beforeHeaderBreakpoint ? 'true' :	
			naviContext.scrollingDirectionIsDown ? 'false' : 'true'} */}
			<Transition
				in={naviContext.isActive ? true :	false}
				timeout={baseDuration}
				appear={true}
				onEntering={fadeInLogo}
				onExiting={fadeOutLogo}
				mountOnEnter
				unmountOnExit
			>
				<div className="navi-animator c5">
					<Navi />
					<div className="social">
						<div className="navi-decor"></div>
						<SocialIcons />
					</div>
				</div>
			</Transition>

			<Transition
				in={naviContext.isActive ? false : true}
				timeout={baseDuration}
				appear={true}
				onEntering={fadeInSocialBar}
				onExiting={fadeOutSocialBar}
			>
				<div className="right-side">
					<div className="social c5">
						<SocialIcons />
					</div>
				</div>
			</Transition>
		</header>
  )
}

export default Header